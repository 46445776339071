import React from "react"

const NotFound = () => {
  return (
    <div>
      <h1>Not Found</h1>
      <p>非常に申し訳ないと思っている。</p>
    </div>
  );
}

export default NotFound